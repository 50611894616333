// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-caption-holder {
		margin-top: 9vh;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 240px;
	max-height: 1080px;

	height: 90vh;
	.owl-caption-holder {
		width: 55%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
		margin-top: 0;
		.owl-container {
			text-align: left;
			display: flex;
			max-width: 100% !important;
			.owl-caption {
				display: flex;
				flex-flow: column;
				text-align: left;
				.owl-description,
				.owl-btn {
					display: none;
				}
				.owl-title {
					order: 1;
					font-weight: bold;
					font-size: 60px;
				}
				.owl-subtitle {
					font-size: 36px;
					font-weight: 400;
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		height: 65vh;
		.owl-caption-holder {
			max-width: 80%;
			max-height: 30%;

			.owl-container {
				.owl-caption {
					margin-left: 1rem;
					.owl-title {
						font-size: 24px;
					}
					.owl-subtitle {
						font-size: 20px;
					}
				}
			}
		}
	}
}

.eyecatcher {
	position: relative;
	.item {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(#000, 0.25);
			z-index: 1;
		}
		.owl-caption-holder {
			z-index: 200;
		}
	}
	div.vacancies {
		z-index: 800;
		position: absolute;
		top: 12vh;
		right: 2.5rem;
		ul li a {
			display: flex;
			flex-flow: column;
			text-align: center;
			align-items: center;
			justify-content: center;
			padding: 15px;
			aspect-ratio: 1/1;
			border-radius: 50%;
			width: 165px;
			text-decoration: none;
			background: $white;
			.list-item-title {
				font-size: 14px;
				color: $black;
			}
			.list-item-suffix {
				font-size: 18px;
				font-weight: bold;
				position: relative;
				&::after {
					content: "\f061";
					font-family: $font-awesome;
					display: block;
					position: absolute;
					left: 50%;
					transform: translate(-50%, -0.35rem);
					font-size: 24px;
					font-weight: 300;
					transition: 0.5s ease;
				}
			}
			&:hover {
				.list-item-suffix::after {
					transform: translate(-20%, -0.35rem);
					transition: 0.5s ease;
				}
			}
			&::after {
				content: "\f8fa";
				font-family: $font-awesome;
				position: absolute;
				top: -5px;
				right: 5px;
				aspect-ratio: 1/1;
				width: 38px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				font-size: 20px;
				background: $black;
			}
		}
		@include media-breakpoint-down(lg) {
			top: unset;
			bottom: 1.5rem;
			right: 1.5rem;
		}
	}
}
