// header
.header {
	z-index: 998;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: transparent;
	transition: 0.5s;
	@include media-breakpoint-down(lg) {
		position: fixed;
	}
	&.sticky {
		box-shadow: $shadow;
		background: $white;
		position: fixed;
		.navbar-nav .nav-link {
			color: $black !important;
		}
		.dropdown-menu.show {
			background: $white !important;
			&::before {
				all: unset;
				display: block !important;
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: calc(100% - 2rem);
				box-shadow: $shadow;
				z-index: -1;
			}
		}
	}
	&.sticky-mobile {
		@include media-breakpoint-down(lg) {
			background: $white;
			.logo {
				order: 0 !important;
				max-width: 120px;
			}
			.book-button {
				flex-basis: auto !important;
			}
			.navbar-toggler i {
				color: $primary;
			}
		}
		.logo {
			&.dark {
				display: block !important;
			}
			&.light {
				display: none !important;
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding-top: 15px;
					padding-bottom: 15px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 1000;
		.navbar-toggler {
			color: $white;
			font-size: 24px;
			i {
				transition: 0.35s ease;
			}
			&[aria-expanded="true"] {
				color: $primary;
				i {
					&::before {
						content: "\f00d";
						font-family: $font-awesome;
					}
					transform: scaleX(-1);
					transition: 0.35s ease;
				}
			}
		}
	}

	// logo
	.logo {
		width: 240px;
		margin: 10px 10px 10px 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			order: 2;
			margin-left: 1rem;
			margin-top: 1rem;
		}
		&.dark {
			display: none;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;
			background: $white;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 999;
			.navbar-nav {
				padding: 12vh 2rem;

				.nav-item {
					border-bottom: 1px solid $orange-lightest;

					.nav-link {
						color: $primary;
						font-size: 18px;
					}

					&.active {
						.nav-link {
							color: $black !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
						}

						.nav-link {
							font-size: 16px;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				gap: 2rem;
				.nav-item {
					.nav-link {
						color: $white;
						font-size: 14px;
						font-weight: bold;
					}
					&.active,
					&:hover {
						.nav-link {
							text-decoration: 2px underline;
							text-underline-offset: 10px;
						}
					}
					.dropdown-menu.show {
						background: transparent;
						border: 0;
						position: relative;
						box-shadow: none;
						&::before,
						&::after {
							display: none;
						}
						&::before {
							display: none;
						}
						.nav-item {
							.nav-link {
								text-decoration: none;
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
	}

	// offerte button
	div.book-button {
		margin-left: 2rem;
		ul {
			a {
				@extend .btn, .btn-secondary;
				color: $orange;
				padding: 0.5rem 1.5rem !important;
				font-size: 14px;
			}
		}
		@include media-breakpoint-down(lg) {
			margin-left: auto;
			margin-right: 0;
			flex-basis: 50%;
			ul li {
				margin-left: auto;
			}
		}
	}
}
