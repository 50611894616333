// footer
&.home {
	.footer {
		.fab-section {
			display: none;
		}
	}
}

.footer {
	padding: 6vh 0 0 0;
	background: $white;
	@include media-breakpoint-down(lg) {
		padding: 1rem 0 0 0;
	}
	.footer-logo {
		.column {
			@include media-breakpoint-down(lg) {
				display: flex;
				flex-flow: column-reverse;
				gap: 1.5rem;
				.logo {
					max-width: 204px;
				}
			}
		}
		padding-bottom: 3vh;
		.vocabulary-term-list {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-flow: row;
			gap: 2rem;
			width: 80%;
			margin-left: auto;
			justify-content: flex-end;
			align-items: center;
			li {
				span.list-item-title {
					display: none;
				}
			}
			@include media-breakpoint-down(lg) {
				position: relative;
				top: unset;
				right: unset;
				width: 100%;
				li img {
					max-height: 38px;
				}
			}
		}
	}
	.footer-contact {
		padding: 0 0 18vh 0;
		.footer-text {
			a {
				font-weight: bold;
				font-size: 18px;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.footer-socket {
		padding-bottom: 10px;
		.list {
			display: flex;
			gap: 1rem;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				gap: 0.3rem;
			}
			a {
				color: $black;
				text-decoration: none;
				font-size: 14px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.fab-section {
		position: fixed;
		bottom: 1rem;
		right: 20px;
		height: 6vh;
		background-color: #ff5722;
		border-radius: 50%;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		transition: transform 0.1s ease-out;

		.vocabulary-term-list {
			li a {
				background: $white;
				display: flex;
				flex-flow: column;
				width: 230px;
				box-shadow: 0px 0px 6px #00000029;
				border-radius: 3px;
				position: relative;
				padding: 10px;
				text-decoration: none;
				&::after {
					content: "\f061";
					font-family: $font-awesome;
					position: absolute;
					right: 10px;
					bottom: 10px;
					font-size: 12px;
					color: $orange;
					transition: 0.35s ease;
				}
				.list-item-title {
					font-size: 14px;
					font-weight: bold;
					color: $orange;
				}
				.list-item-suffix {
					font-size: 14px;
					color: $black;
				}
				&:hover {
					.list-item-title {
						color: $black;
					}
					&::after {
						transition: 0.35s ease;
						transform: translateX(3px);
					}
				}
			}
		}
	}
}
