// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home start section
.home-start-section {
	padding: 25px 0 !important;
	.container-fluid {
		.container-holder {
			.column {
				display: flex;
				flex-flow: row;
				justify-content: space-between;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}
				.link a {
					display: block;
					max-width: 0px;
					max-height: 0px;
					position: relative;
					&:hover {
						&::after {
							transform: translateY(25%);
							transition: 0.5s ease;
						}
					}
					&::after {
						content: "\f063";
						font-family: $font-awesome;
						position: absolute;
						color: $white;
						font-size: 24px;
						font-weight: 300;
						transition: 0.5s ease;
					}
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}
				.wysiwyg {
					ul {
						display: flex;
						height: 100%;
						align-items: center;
						gap: 2rem;
						margin-left: auto;
						margin-right: calc(2.5rem + 230px);
						list-style: none;
						@include media-breakpoint-down(lg) {
							margin-right: 0 !important;
							flex-flow: column;
							gap: 0.3rem;
							align-items: flex-end;
						}

						li a {
							text-decoration: none;
							font-size: 14px;
							@include media-breakpoint-down(lg) {
								text-align: right;
							}
						}
					}
				}
				.vocabulary-term-list.fab {
					position: fixed;
					bottom: 1rem;
					right: 20px;
					height: 6vh;
					background-color: #ff5722;
					border-radius: 50%;
					box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
					transition: transform 0.1s ease-out;
					z-index: 900;
					gap: 0.5rem;

					li a {
						background: $white;
						display: flex;
						flex-flow: column;
						width: 230px;
						box-shadow: 0px 0px 6px #00000029;
						border-radius: 3px;
						position: relative;
						padding: 10px;
						text-decoration: none;
						gap: 0.5rem;

						&::after {
							content: "\f061";
							font-family: $font-awesome;
							position: absolute;
							right: 10px;
							bottom: 10px;
							font-size: 12px;
							color: $orange;
							transition: 0.35s ease;
						}

						.list-item-title {
							font-size: 14px;
							font-weight: bold;
							color: $orange;
							font-family: $font-family-primary;
						}

						.list-item-suffix {
							font-size: 14px;
							color: $black;
							font-family: $font-family-primary;
						}

						&:hover {
							.list-item-title {
								color: $black;
							}

							&::after {
								transition: 0.35s ease;
								transform: translateX(3px);
							}
						}
					}
					@include media-breakpoint-down(md) {
						position: relative;
						bottom: unset;
						right: unset;
						height: unset;
						margin-left: auto;
					}
				}
			}
		}
	}
}

// home-intro
.home-intro-section {
	.container-fluid {
		.container-holder {
			max-width: 55%;
			h2 {
				font-weight: 300;
			}
			@include media-breakpoint-down(lg) {
				max-width: 100%;
				h2 {
					font-size: 20px;
				}
			}
			.wysiwyg {
				a.btn {
					margin-top: 1.5rem;
					margin-right: 0.55rem;
					@include media-breakpoint-down(lg) {
						margin-top: 0.4rem;
					}
				}
			}
		}
	}
}

// home service
.home-service-section {
	padding: 9vh 0;
	.container {
		margin-bottom: 6vh;
		.wysiwyg {
			max-width: 70%;
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
		}
		.grid-items {
			justify-content: flex-start;
			gap: 0.8rem;
			max-width: 100%;
			flex-flow: row nowrap;
			@include media-breakpoint-down(md) {
				margin: auto;
				flex-flow: row wrap;
			}
			.item {
				padding: 0 !important;
				max-width: 16% !important;
				flex-basis: 16% !important;
				@include media-breakpoint-down(lg) {
					max-width: 45% !important;
					flex-basis: 45% !important;
				}
				.card {
					width: 100%;
					aspect-ratio: 1/1;
					border: 0;
					box-shadow: none;
					background: $white;
					position: relative;
					box-shadow: 0px 3px 6px #00000029;
					.card-img-overlay {
						background: $white;
						transition: 0.35s ease;
					}
					.card-image,
					.card-description,
					.card-subtitle {
						display: none;
					}
					.card-title {
						font-size: 20px;
						font-weight: bold;
						color: $orange-two;
						text-shadow: none;
					}
					&::after {
						content: "\f061";
						font-family: $font-awesome;
						position: absolute;
						right: 13px;
						bottom: 15px;
						font-size: 12px;
						color: $orange;
						transition: 0.35s ease;
					}
					&:hover {
						.card-img-overlay {
							background: $orange-lightest;
							transition: 0.35s ease;
						}
						.card-title {
							color: $black;
						}
						&::after {
							color: $black;
							transform: translateX(0.35rem);
							transition: 0.35s ease;
						}
					}
				}
				&:nth-last-child(1) {
					.card {
						.card-img-overlay {
							background: $orange;
						}
						.card-title {
							color: $white;
						}
						&::after {
							display: none;
						}
					}
				}
			}
		}
	}
}

// home usp section

.home-usp-section {
	padding: 6vh 0;
	.column {
		@include media-breakpoint-down(lg) {
			padding: 0 !important;
		}
	}
	.grid-items {
		flex-flow: column;
		gap: 4vh;
		.item {
			max-width: 100% !important;
			flex-basis: 100% !important;
			@include media-breakpoint-down(lg) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
			.card {
				border-radius: 4px;
				border: 0;
				box-shadow: none;
				.card-image {
					max-width: 48% !important;
					flex-basis: 48% !important;
				}
				.card-body {
					max-width: 52% !important;
					flex-basis: 52% !important;
				}
				.card-caption {
					display: flex;
					justify-content: center;
					flex-flow: column;
					padding: 1rem;
					max-width: 65%;
					margin: auto;
					ul {
						list-style: none;
						padding: 0;
						gap: 0.65rem;
						display: flex;
						flex-flow: column;
						margin-top: 1.5rem;
						li {
							display: flex;
							gap: 0.5rem;
							align-items: center;
							font-size: 18px;
							font-weight: bold;
							color: $orange;
							&::before {
								content: "\f00c";
								font-family: $font-awesome;
								font-size: 12px;
							}
						}
					}
					.card-title {
						font-size: 48px;
					}
				}
				.card-buttons {
					display: none;
				}
				@include media-breakpoint-down(lg) {
					flex-flow: column !important;
					margin: 0;
					.card-image,
					.card-body {
						max-width: 100% !important;
						flex-basis: 100% !important;
					}
					.card-caption {
						max-width: 100%;
					}
					.card-title {
						font-size: 24px !important;
					}
				}
			}
			&:nth-child(even) {
				.card {
					flex-flow: row-reverse !important;
					@include media-breakpoint-down(lg) {
						flex-flow: column !important;
					}
					.card-body {
						.card-caption {
							margin-left: auto;
							width: 90% !important;
							max-width: 90% !important;
							@include media-breakpoint-down(lg) {
								width: 100% !important;
								max-width: 100% !important;
							}
						}
					}
				}
			}
		}
	}
}
// advice section
.home-advice-section {
	.container {
		.container-holder {
			max-width: 75%;
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}

			.wysiwyg {
				h2 {
					font-weight: 300;
				}
				a.btn {
					margin-top: 1.5rem;
					margin-right: 0.55rem;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}
	.subtitle,
	.subtitle * {
		font-weight: 200;
		color: $primary;
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
