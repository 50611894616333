// btn
.btn {
	box-shadow: none;
	font-weight: bold;
	font-size: 14px;
	padding: 12.5px 22.5px;
	border-radius: 3px;
	position: relative;
	overflow: hidden;
	z-index: 200;
	border: 0;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
		width: 0;
		height: 100%;
		transition: 0.25s ease;
		z-index: -1;
	}

	&:hover {
		transition: 0.25s ease;

		&::before {
			width: 100%;
			transition: 0.25s ease;
		}
	}
	// btn-primary

	&.btn-primary {
		&::before {
			background: $orange-lightest;
		}
		&:hover {
			color: $orange-two;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&::before {
			background: $primary;
		}

		&:hover {
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		&::before {
			background: $black !important;
		}
		&:hover {
			color: $orange-lightest;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&::before {
			background: $white;
		}

		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	text-decoration: none;
	font-weight: 600;
	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
